// Footer.js
import React from "react";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";

const Footer = () => (
  <footer className="footer_container">
    <ScrollToTopButton />
  </footer>
);

export default Footer;
